<template>
  <div class="fault-record">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
        <el-row :gutter="20">
          <el-col :span="5">
            <!-- 阿米巴 -->
            <ml-select
              prop="companyId"
              placeholder="请选择阿米巴"
              :options="unitOptions"
              label="阿米巴:"
              keyName="companyId"
              labelName="companyName"
              valueName="companyId"
              v-model="searchData.companyId"
              @change="selectUnit"
              clearable
            />
          </el-col>
          <el-col :span="5">
            <!-- 所属项目 -->
            <ml-select
              prop="projectId"
              placeholder="请选所属项目"
              :options="projectData"
              label="所属项目:"
              keyName="projectId"
              labelName="projectName"
              valueName="projectId"
              v-model="searchData.projectId"
              @change="searchFn"
              clearable
            />
          </el-col>
          <el-col :span="7">
            <!-- 日期 -->
            <ml-date-picker
              prop="date"
              startPlaceholder="开始日期"
              endPlaceholder="结束日期"
              label="操作时间:"
              type="datetimerange"
              v-model="searchData.date"
              clearable
            />
          </el-col>
          <el-col :span="6">
            <!-- 资产类型 -->
            <ml-select
              prop="propertyType"
              placeholder="请选资产类型"
              :options="propertyTypeList"
              label="资产类型:"
              keyName="label"
              labelName="label"
              valueName="label"
              v-model="searchData.propertyType"
              @change="searchFn"
              clearable
            />
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">
            <!-- 设备类型 -->
            <ml-select
              prop="deviceType"
              placeholder="请选择设备类型"
              :options="deviceTypeList"
              label="设备类型"
              keyName="label"
              labelName="label"
              valueName="label"
              v-model="searchData.deviceType"
              @change="searchFn"
              clearable
            />
          </el-col>

          <el-col :span="5">
            <!-- 故障等级 -->
            <ml-select
              prop="severity"
              placeholder="请选择故障等级"
              :options="faultLevelData"
              label="故障等级:"
              labelName="label"
              valueName="label"
              v-model="searchData.severity"
              :style="{ marginBottom: 0 }"
              @change="searchFn"
              clearable
            />
          </el-col>

          <el-col :span="7">
            <ml-input
              prop="deviceNameLike"
              placeholder="请输入设备名称"
              style="margin-bottom: 0"
              label="设备名称:"
              v-model="searchData.deviceNameLike"
            />
          </el-col>
          <el-col :span="6">
            <!-- 搜索按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="搜索"
              submitIcon="el-icon-search"
              cancelText="重置"
              cancelIcon="el-icon-refresh"
              cancelType="danger"
              @click-cancel="resetSearchData"
              @click-submit="searchFn"
            />
            <!-- 按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="导出"
              submitIcon="el-icon-download"
              @click-submit="errorRecordbleDown"
              :showCancel="false"
            />
          </el-col>
        </el-row>
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table
        :data="tabberData.data"
        style="width: 100%"
        :height="tabberHeight(400)"
        @select-all="selectAll"
        @select="select"
        :row-key="row => row.errorId"
        ref="errorRecordbleRef"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <el-table-column
          align="center"
          label="上报类型"
          prop="reportType"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="故障上报人" prop="reportUser" min-width="10%" />
        <!-- <el-table-column align="center" label="故障处理人" prop="gzclr" min-width="15%" /> -->
        <el-table-column align="center" label="事件标题" prop="title" min-width="15%" />
        <el-table-column align="center" label="设备名称" prop="deviceName" min-width="10%" />
        <el-table-column
          align="center"
          label="设备地址"
          prop="address"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="事件时间" prop="eventTime" width="100" />
        <el-table-column align="center" label="故障等级" prop="severity" width="80" />
        <el-table-column align="center" label="申报时间" prop="submitTime" width="100" />
        <el-table-column align="center" label="附件" prop="storageAddress" width="80">
          <template #default="scope">
            <el-button
              v-if="scope.row.attachVos"
              type="text"
              @click="clickAttachVosView(scope.$index, scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="故障状态" prop="showStatusText" width="80" />
        <el-table-column align="center" label="操作" priop="partId" width="240">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
              v-if="isSuperAdmin"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickDetails(scope.$index, scope.row)"
            >
              查看故障详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 查看附件提示框 -->
    <ml-dialog
      width="600px"
      customClass="attachVosView-dialog"
      ref="attachVosViewDialogRef"
      title="附件列表"
      :showBtn="false"
      :showClose="true"
    >
      <template #body>
        <ml-list
          fileName="fileName"
          :showDelete="false"
          :showDown="true"
          :list="attachVosViewDialogData.list"
        />
      </template>
    </ml-dialog>
    <!-- 办结提示框 -->
    <ml-dialog
      ref="deleteErrorDialogRef"
      content="确定删除该故障记录吗？"
      @click-submit="deleteErrorDialogSubmit"
    ></ml-dialog>

    <!-- 批量导出设备提示框 -->
    <ml-dialog
      ref="errorRecordbleDialogDialogRef"
      :content="errorRecordbleDialogDialogData.content"
      :title="errorRecordbleDialogDialogData.title"
      :showSubmitBtn="selectData.length > 0"
      @click-submit="submitErrorRecordbleDialog"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  getPropertyTypeList,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'FaultRecord',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { deviceId } = route.params
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    // 是否是超级管理员
    const isSuperAdmin = computed(() => getters.getIsDivisionAdmin)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      severity: '',
      propertyType: getMemoryPage.value.searchData.propertyType || '',
      deviceType: getMemoryPage.value.searchData.deviceType || '',
      date: getMemoryPage.value.searchData.date || [],
      deviceNameLike: getMemoryPage.value.searchData.deviceNameLike || ''
    })
    const clickErrorStatus = val => {
      searchData.searchStatus = val
      searchFn()
    }
    // 单位名下拉列表
    const unitOptions = ref([])
    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    // 监听资产类型变化
    watch(
      () => searchData.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          deviceTypeList.value = propertyTypeList.value.filter(
            item => item.label === newvalue
          )[0].value
        }
      }
    )

    // 所属项目配置
    const projectData = ref([])
    // 故障等级
    const faultLevelData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const selectUnit = () => {
      searchData.projectVos = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        }
      }
    )

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData, { date: [] })
      projectData.value = []
      tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getFaultServiceCenterTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'], true)
      if (searchData['date'] && searchData['date'].length > 0) {
        const [submitTimeStart, submitTimeEnd] = searchData['date']
        searchDataParams.submitTimeStart = formetData(submitTimeStart)
        searchDataParams.submitTimeEnd = formetData(submitTimeEnd)
      }
      if (deviceId) {
        searchDataParams.deviceId = deviceId
      }
      searchDataParams.deviceNameLike = replacePerCent(searchData.deviceNameLike)
      const { data } = await getTabberData(
        dispatch,
        'fetchDeviceErrorRecordsPage',
        params,
        searchDataParams
      )
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getFaultServiceCenterTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 查看附件列表
    const attachVosViewDialogRef = ref()
    const attachVosViewDialogData = reactive({
      list: []
    })
    const clickAttachVosView = (index, row) => {
      attachVosViewDialogData.list = row.attachVos || []
      attachVosViewDialogRef.value.showDialog = true
    }
    // 修改设备
    const clickDetails = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { errorId, historyData } = row
      if (historyData == 1) {
        router.push({ path: '/faultServiceCenterDetails', query: { errorId } })
      } else {
        router.push({ path: '/newFaultServiceCenterDetails', query: { errorId } })
      }
    }

    // 删除故障
    const deleteErrorDialogRef = ref()
    const deleteErrorDialogData = reactive({
      errorId: ''
    })
    const clickDelete = (index, row) => {
      const { errorId } = row
      deleteErrorDialogData.errorId = errorId
      deleteErrorDialogRef.value.showDialog = true
    }
    const deleteErrorDialogSubmit = () => {
      dispatch('fetchDeleteBatchErrorRecord', [deleteErrorDialogData.errorId]).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          deleteErrorDialogRef.value.showDialog = false
          tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
        }
      })
    }

    // 选中的添加到列表
    const errorRecordbleRef = ref()
    // const getSelection = selection => selection.map(item => item)
    // 选择指派人列表
    const selectData = ref([])
    // 选中单个
    const select = selection => {
      selectData.value = selection
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = selection
    }
    const errorRecordbleDialogDialogRef = ref()
    const errorRecordbleDialogDialogData = reactive({
      title: '批量导出',
      content: ''
    })
    // 弹出层
    const errorRecordbleDown = () => {
      if (selectData.value.length <= 0) {
        errorRecordbleDialogDialogData.content = '请选择故障记录'
      } else {
        errorRecordbleDialogDialogData.content = '确定导出选中的故障记录吗？'
      }
      errorRecordbleDialogDialogRef.value.showDialog = true
    }
    const jsonFields = reactive({
      阿米巴: 'companyName',
      所属项目: 'projectName',
      故障上报人: 'reportUser',
      事件标题: 'title',
      设备名称: 'deviceName',
      设备地址: 'address',
      事件时间: 'eventTime',
      故障等级: 'severity',
      申报时间: 'submitTime',
      故障状态: 'showStatusText',
      故障描述: 'errorDesc'
    })

    // 确定
    const { export_json_to_excel } = require('../../utils/excel/Export2Excel')
    const formatJson = (filterVal, jsonData) => jsonData.map(v => filterVal.map(j => v[j]))
    const submitErrorRecordbleDialog = () => {
      //导出的方法
      require.ensure([], () => {
        const tHeader = []
        // 上面设置Excel的表格第一行的标题
        const filterVal = []

        Object.keys(jsonFields).map(item => {
          tHeader.push(item)
          filterVal.push(jsonFields[item])
        })
        // 上面的index、nickName、name是tableData里对象的属性
        const list = selectData.value //把data里的tableData存到list
        const data = formatJson(filterVal, list)
        // console.log(data)
        // console.log(filterVal)
        // console.log(list)

        export_json_to_excel(tHeader, data, '列表excel')
        selectData.value = []
        errorRecordbleRef.value.clearSelection()
        errorRecordbleDialogDialogRef.value.showDialog = false
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []

      getFaultServiceCenterTabberData()

      getProjectData(searchData.companyId || '')
    })
    return {
      jsonFields,
      errorRecordbleRef,
      selectData,
      select,
      selectAll,
      errorRecordbleDialogDialogRef,
      errorRecordbleDialogDialogData,
      errorRecordbleDown,
      submitErrorRecordbleDialog,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickDetails,
      unitOptions,
      propertyTypeList,
      deviceTypeList,
      projectData,
      faultLevelData,
      selectUnit,
      clickErrorStatus,
      formetData,
      attachVosViewDialogRef,
      attachVosViewDialogData,
      clickAttachVosView,
      clickDelete,
      deleteErrorDialogRef,
      deleteErrorDialogData,
      deleteErrorDialogSubmit,
      isSuperAdmin
    }
  }
}
</script>

<style lang="scss">
.fault-record {
  @extend %params-global;
}
.attachVosView-dialog {
  .el-dialog__body {
    justify-content: start;
  }
}
</style>
